<script setup>
import { ref } from 'vue';
import { getDataObjectById } from 'o365-dataobject';
import SJAMatrix from 'sja.vue.libraries.components.SJAMatrix.vue'
import MobileSJAMatrix from 'sja.vue.libraries.mob.MobileSJAMatrix.vue'
import { modalInitialMatrix, procGetMatrix } from 'sja.vue.libraries.modules.utilities.js'
import { isMobile } from 'o365.GlobalState.ts';

let dsProbabilitiesLkp = getDataObjectById(`dsProbabilitiesLkp`)
let dsConsequencesLkp = getDataObjectById(`dsConsequencesLkp`)
let dsHazardsModalInitial = getDataObjectById(`dsTasksHazardsModalInitial`)

let refInitialProbabilityValue = ref(null);
let refInitialConsequenceValue = ref(null);
let refInitialProbability_ID = ref(null);
let refInitialConsequence_ID = ref(null);

async function updateMatrix() {
    let result;
    try {
        result = await procGetMatrix.execute({
            ProbabilityValue: refInitialProbabilityValue.value ? refInitialProbabilityValue.value : dsHazardsModalInitial.current.ProbabilityValue,
            ConsequenceValue: refInitialConsequenceValue.value ? refInitialConsequenceValue.value : dsHazardsModalInitial.current.ConsequenceValue,
            Probability_ID: refInitialProbability_ID.value ? refInitialProbability_ID.value : dsHazardsModalInitial.current.ProbabilityValueID,
            Consequence_ID: refInitialConsequence_ID.value ? refInitialConsequence_ID.value : dsHazardsModalInitial.current.ConsequenceValueID
        });

        dsHazardsModalInitial.current.Matrix_ID = result.Table[0].ID

    } catch (error) {
        console.log(error);
    }
}

async function saveMatrix() {
    let dsTasksHazards = getDataObjectById(`dsTasksHazards_${dsHazardsModalInitial.current.SJA_ID}`);
    await dsHazardsModalInitial.save().then(() => dsTasksHazards.load())
    dsHazardsModalInitial.load()
}

function setRefs(sel, refType) {
    if (refType == "IC") {
        refInitialConsequenceValue.value = sel.Value;
        refInitialConsequence_ID.value = sel.ID;
    }
    if (refType == "IP") {
        refInitialProbabilityValue.value = sel.Value;
        refInitialProbability_ID.value = sel.ID;
    }
    updateMatrix();
}

dsHazardsModalInitial.on("FieldChanged", () => {
    if ((dsHazardsModalInitial.current.ConsequenceNameAndDesc != null && dsHazardsModalInitial.current.ProbabilityNameAndDesc != null) && dsHazardsModalInitial.current._state.hasChanges) {
        document.querySelector('.save-btn-initial').removeAttribute('disabled')
        document.querySelector('.undo-btn-initial').removeAttribute('disabled')
    } else {
        document.querySelector('.save-btn-initial').setAttribute('disabled', 'disabled')
        document.querySelector('.undo-btn-initial').setAttribute('disabled', 'disabled')
    }
});

dsHazardsModalInitial.on('ChangesCancelled', () => {
    refInitialProbabilityValue.value = null;
    refInitialConsequenceValue.value = null;
    refInitialProbability_ID.value = null;
    refInitialConsequence_ID.value = null;
    document.querySelector('.save-btn-initial').setAttribute('disabled', 'disabled')
    document.querySelector('.undo-btn-initial').setAttribute('disabled', 'disabled')

});

dsHazardsModalInitial.on("AfterSave", async function () {
    refInitialProbabilityValue.value = null;
    refInitialConsequenceValue.value = null;
    refInitialProbability_ID.value = null;
    refInitialConsequence_ID.value = null;
    document.querySelector('.save-btn-initial').setAttribute('disabled', 'disabled')
    document.querySelector('.undo-btn-initial').setAttribute('disabled', 'disabled')
});

function onModalHidden() {
    document.querySelector('.save-btn-initial').setAttribute('disabled', 'disabled')
    document.querySelector('.undo-btn-initial').setAttribute('disabled', 'disabled')
}

</script>

<template>
    <OModal ref="modalInitialMatrix" @hidden="onModalHidden()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Initial Risk Assessment') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="dsHazardsModalInitial.cancelChanges()"></button>
                </div>
                <div class="modal-body">
                    <div class="d-flex flex-column flex-lg-row">
                        <div class="d-flex flex-fill flex-column m-2 justify-content-center">
                            <h6>{{ $t('Consequence') }}*</h6>
                            <ODataLookup class="form-control my-3" :data-object="dsConsequencesLkp" :disableRecent="true"
                                v-model="dsHazardsModalInitial.current.ConsequenceNameAndDesc"
                                :bind="sel => { dsHazardsModalInitial.current.ConsequenceNameAndDesc = sel.ValueAndNameAndDescription; setRefs(sel, 'IC') }">
                                <OColumn :headerName="$t('Value')" field="Value" width="60"></OColumn>
                                <OColumn :headerName="$t('Description')" field="NameAndDescription" width="350"></OColumn>
                            </ODataLookup>
                            <h6>{{ $t('Probability') }}*</h6>
                            <ODataLookup class="form-control my-3" :data-object="dsProbabilitiesLkp" :disableRecent="true"
                                v-model="dsHazardsModalInitial.current.ProbabilityNameAndDesc"
                                :bind="sel => { dsHazardsModalInitial.current.ProbabilityNameAndDesc = sel.ValueAndNameAndDescription; setRefs(sel, 'IP') }">
                                <OColumn :headerName="$t('Value')" field="Value" width="60"></OColumn>
                                <OColumn :headerName="$t('Description')" field="NameAndDescription" width="350"></OColumn>
                            </ODataLookup>
                        </div>

                        <div class="d-flex flex-column flex-fill align-items-center">
                            <h6>{{ $t('Matrix') }}</h6>
                            <MobileSJAMatrix v-if="isMobile" :hazardRow="dsHazardsModalInitial.data" matrixType="Initial" class="m-2" />
                            <SJAMatrix v-else :hazardRow="dsHazardsModalInitial.data" matrixType="Initial" class="m-2" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button disabled type="button" class="btn btn-primary save-btn-initial"
                        @click="saveMatrix()">{{ $t('Save') }}</button>
                    <button disabled type="button" class="btn btn-secondary undo-btn-initial"
                        @click="dsHazardsModalInitial.cancelChanges()">{{ $t('Undo') }}</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close"
                        @click="saveMatrix()">{{ $t('Save & Close') }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>